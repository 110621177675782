<template functional>
	<nav :class="[data.class, data.staticClass]" class="flex space-x-4 tablet:space-x-8 text-14">
		<template v-for="(crumb, index) in props.crumbs">
			<span v-if="!!index" :key="'separator' + index" :class="[$options.methods.themeClass(props.theme).separator]"> &gt; </span>
			<nuxt-link v-if="!!crumb.href" :key="'link' + index" :to="crumb.href" :class="[$options.methods.themeClass(props.theme).link]" class="whitespace-nowrap overflow-hidden text-ellipsis">{{ crumb.text }}</nuxt-link>
			<span v-else :key="'text' + index" :class="[$options.methods.themeClass(props.theme).text]" class="whitespace-nowrap overflow-hidden text-ellipsis">{{ crumb.text }}</span>
		</template>
	</nav>
</template>

<script>
export default {
	props: {
		crumbs: {
			type: Array,
			default: () => [],
		},
		theme: {
			type: String,
			default: "dark",
			validator: (theme) => ["dark", "light"].includes(theme),
		},
	},
	methods: {
		themeClass(theme) {
			return {
				light: {
					link: "text-goSky hover:text-white active:opacity-80",
					separator: "text-white opacity-40",
					text: "text-white opacity-75",
				},
				dark: {
					link: "text-goBlue hover:text-goPinkDark active:text-goPinkDarker",
					separator: "text-goGrayBright",
					text: "text-goGrayDarker",
				},
			}[theme];
		},
	},
};
</script>
