<template>
	<div class="relative grid min-h-495 tablet:min-h-720 tabletWide:min-h-696 desktop:min-h-618" data-test="pageHeader">
		<div class="tablet:mx-48 desktop:mx-56">
			<div class="pt-16 tablet:pt-24 pb-32 tablet:pb-24 px-16 tablet:px-48 desktop:px-56 max-w-375 tablet:max-w-none desktop:mx-auto desktop:max-w-1440 flex flex-col h-full space-y-24">
				<breadCrumb v-if="!!breadcrumb" v-bind="breadcrumb" />

				<div class=" flex-1 flex flex-col justify-end tablet:justify-center space-y-24 tablet:space-y-16 tabletWide:space-y-32">
					<h1 v-if="!!title" class="flex flex-col text-white font-bold space-y-6 tablet:space-y-12 ">
						<span v-if="!!titleTag" class="text-20 tablet:text-24" v-text="titleTag" />
						<span v-if="!!title" class="text-40 tablet:text-64" data-test="title" v-html="title" />
					</h1>

					<p v-if="!!headline" class="text-white text-18 tablet:text-22 " v-html="headline" />

					<div class="space-y-32">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>

		<figure class="absolute inset-0 -z-10 rounded-GO overflow-hidden tablet:ml-48 desktop:ml-56">
			<LazyHydrate never>
				<goPicture v-bind="picture" :enable-placeholder="false" class="h-full" />
			</LazyHydrate>
		</figure>

		<div class="absolute inset-0 -z-10 rounded-GO overflow-hidden tablet:ml-48 desktop:ml-56 bg-goPageHeaderGradientMobile tablet:bg-goPageHeaderGradientTablet tabletWide:bg-goPageHeaderGradientTabletWide desktop:bg-goPageHeaderGradientDesktop desktopWide:bg-goPageHeaderGradientDesktopWide"></div>
	</div>
</template>

<script>
import breadCrumb from '~/components/breadCrumb.vue';

export default {
	components: {
		breadCrumb,
	},
	inheritAttrs: false,
	props: {
		titleTag: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			required: true,
		},
		headline: {
			type: String,
			default: "",
		},
		breadcrumb: {
			type: null,
			default: () => null,
			validator: (obj) => obj.constructor === Object || obj === null,
		},
		picture: {
			type: Object,
			required: true,
		},
	},
};
</script>
